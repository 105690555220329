<template>
  <div class="alls">
    <PCHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="content">
      <div class="flex row-between row-center t-001">
        <div class="w-1">
          <div class="ptop">
            <PCPrescriptionSwipper :fromIndex="index" />
          </div>
        </div>
        <div class="w-2">
          <div>
            <div class="txt-42">
              {{ $t('message.prescription.prescription_lenses') }}
            </div>
            <div class="txt-21 m-t-8" style="font-family: Oswald">
              {{ $t('message.prescription.money') }}
            </div>
            <div class="txt-16 m-t-8 color-07">
              {{ $t('message.prescription.order_for') }}
            </div>
            <div class="txt-16 m-t-8 color-07">
              {{ $t('message.prescription.experience') }}
            </div>
            <div class="txt-16 m-t-8 color-07">
              {{ $t('message.prescription.smart_glasses') }}
            </div>
            <div class="paddd">
              <div class="txt-27 m-t-24">
                {{ $t('message.prescription.prescription_information') }}
              </div>
              <div class="txt-16 color-blue" @click="tcc">
                {{ $t('message.prescription.how_to_read') }}
              </div>

              <div class="m-t-24 flex row-between row-center">
                <div>
                  <p class="txt-16">
                    {{ $t('message.prescription.right_sphere') }}
                  </p>
                  <select
                    class="sl"
                    :class="right_sphere == -100 ? 'scolor07' : ''"
                    v-model="right_sphere"
                  >
                    <option disabled selected value="-100">
                      {{ $t('message.prescription.select') }}
                    </option>
                    <option>-4.25</option>
                    <option>-4</option>
                    <option>-3.75</option>
                    <option>-3.5</option>
                    <option>-3.25</option>
                    <option>-3</option>
                    <option>-2.75</option>
                    <option>-2.5</option>
                    <option>-2.25</option>
                    <option>-2</option>
                    <option>-1.75</option>
                    <option>-1.5</option>
                    <option>-1.25</option>
                    <option>-1</option>
                    <option>-0.75</option>
                    <option>-0.5</option>
                    <option>-0.25</option>
                    <option>0</option>
                    <option>0.25</option>
                    <option>0.5</option>
                    <option>0.75</option>
                    <option>1</option>
                    <option>1.25</option>
                    <option>1.5</option>
                    <option>1.75</option>
                    <option>2</option>
                    <option>2.25</option>
                    <option>2.5</option>
                    <option>2.75</option>
                    <option>3</option>
                    <option>3.25</option>
                    <option>3.5</option>
                    <option>3.75</option>
                    <option>4</option>
                  </select>
                </div>
                <div>
                  <div class="txt-16">
                    {{ $t('message.prescription.left_sphere') }}
                  </div>
                  <div class="m-t-074">
                    <select
                      class="sl"
                      :class="left_sphere == -100 ? 'scolor07' : ''"
                      v-model="left_sphere"
                    >
                      <option disabled selected value="-100">
                        {{ $t('message.prescription.select') }}
                      </option>
                      <option>-4.25</option>
                      <option>-4</option>
                      <option>-3.75</option>
                      <option>-3.5</option>
                      <option>-3.25</option>
                      <option>-3</option>
                      <option>-2.75</option>
                      <option>-2.5</option>
                      <option>-2.25</option>
                      <option>-2</option>
                      <option>-1.75</option>
                      <option>-1.5</option>
                      <option>-1.25</option>
                      <option>-1</option>
                      <option>-0.75</option>
                      <option>-0.5</option>
                      <option>-0.25</option>
                      <option>0</option>
                      <option>0.25</option>
                      <option>0.5</option>
                      <option>0.75</option>
                      <option>1</option>
                      <option>1.25</option>
                      <option>1.5</option>
                      <option>1.75</option>
                      <option>2</option>
                      <option>2.25</option>
                      <option>2.5</option>
                      <option>2.75</option>
                      <option>3</option>
                      <option>3.25</option>
                      <option>3.5</option>
                      <option>3.75</option>
                      <option>4</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="m-t-24 flex row-between row-center">
                <div>
                  <div class="txt-16">
                    {{ $t('message.prescription.right_cylinder') }}
                  </div>
                  <div class="m-t-074">
                    <select
                      class="sl"
                      :class="rightCy == 0 ? 'scolor07' : ''"
                      v-model="rightCy"
                    >
                      <option value="0">
                        {{ $t('message.prescription.none') }}
                      </option>
                      <option>0.25</option>
                      <option>0.5</option>
                      <option>0.75</option>
                      <option>1</option>
                      <option>1.25</option>
                      <option>1.5</option>
                      <option>1.75</option>
                      <option>2</option>
                      <option>2.25</option>
                      <option>2.5</option>
                      <option>2.75</option>
                      <option>3</option>
                      <option>3.25</option>
                      <option>3.5</option>
                    </select>
                  </div>
                </div>
                <div>
                  <div class="txt-16">
                    {{ $t('message.prescription.left_cylinder') }}
                  </div>
                  <div class="m-t-074">
                    <select
                      class="sl"
                      :class="leftCy == 0 ? 'scolor07' : ''"
                      v-model="leftCy"
                    >
                      <option value="0">
                        {{ $t('message.prescription.none') }}
                      </option>
                      <option>0.25</option>
                      <option>0.5</option>
                      <option>0.75</option>
                      <option>1</option>
                      <option>1.25</option>
                      <option>1.5</option>
                      <option>1.75</option>
                      <option>2</option>
                      <option>2.25</option>
                      <option>2.5</option>
                      <option>2.75</option>
                      <option>3</option>
                      <option>3.25</option>
                      <option>3.5</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="m-t-24 flex row-between row-center">
                <div>
                  <div class="txt-16" :class="rightCy > 0 ? '' : 'none'">
                    {{ $t('message.prescription.right_axis') }}
                  </div>
                  <div class="m-t-074">
                    <input
                      type="number"
                      :placeholder="this.$t('message.prescription.none')"
                      class="sl"
                      oninput="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                      min="1"
                      max="180"
                      :class="[
                        rightCy > 0 ? '' : 'none',
                        right_axis > 180 ? 'error' : '',
                      ]"
                      v-model="right_axis"
                      :disabled="rightCy == 0"
                    />
                  </div>
                </div>
                <div>
                  <div class="txt-16" :class="leftCy > 0 ? '' : 'none'">
                    {{ $t('message.prescription.left_axis') }}
                  </div>
                  <div class="m-t-074">
                    <input
                      type="number"
                      class="sl"
                      :placeholder="this.$t('message.prescription.none')"
                      oninput="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                      :class="[
                        leftCy > 0 ? '' : 'none',
                        left_axis > 180 ? 'error' : '',
                      ]"
                      v-model="left_axis"
                      :disabled="leftCy == 0"
                    />
                  </div>
                </div>
              </div>

              <div
                class="m-t-24 flex row-between row-center"
                v-show="isShowPad"
              >
                <div>
                  <div class="txt-16">
                    {{ $t('message.prescription.right_pd') }}
                  </div>
                  <div class="m-t-074">
                    <select
                      class="sl"
                      :class="rightPad == 0 ? 'scolor07' : ''"
                      v-model="rightPad"
                    >
                      <option disabled selected value="0">
                        {{ $t('message.prescription.select') }}
                      </option>
                      <option>27.5</option>
                      <option>28</option>
                      <option>28.5</option>
                      <option>29</option>
                      <option>29.5</option>
                      <option>30</option>
                      <option>30.5</option>
                      <option>31</option>
                      <option>31.5</option>
                      <option>32</option>
                      <option>32.5</option>
                    </select>
                  </div>
                </div>
                <div>
                  <div class="txt-16">
                    {{ $t('message.prescription.left_pd') }}
                  </div>
                  <div class="m-t-074">
                    <select
                      class="sl"
                      :class="leftPad == 0 ? 'scolor07' : ''"
                      v-model="leftPad"
                    >
                      <option disabled selected value="0">
                        {{ $t('message.prescription.select') }}
                      </option>
                      <option>27.5</option>
                      <option>28</option>
                      <option>28.5</option>
                      <option>29</option>
                      <option>29.5</option>
                      <option>30</option>
                      <option>30.5</option>
                      <option>31</option>
                      <option>31.5</option>
                      <option>32</option>
                      <option>32.5</option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                class="m-t-24 flex row-between row-center"
                v-show="!isShowPad"
              >
                <div>
                  <div class="txt-16">{{ $t('message.prescription.pd') }}</div>
                  <div class="m-t-074">
                    <select
                      class="sl"
                      :class="pad == 0 ? 'scolor07' : ''"
                      v-model="pad"
                    >
                      <option disabled selected value="0">
                        {{ $t('message.prescription.select') }}
                      </option>
                      <option>55</option>
                      <option>56</option>
                      <option>57</option>
                      <option>58</option>
                      <option>59</option>
                      <option>60</option>
                      <option>61</option>
                      <option>62</option>
                      <option>63</option>
                      <option>64</option>
                      <option>65</option>
                      <option>66</option>
                      <option>67</option>
                      <option>68</option>
                      <option>69</option>
                      <option>70</option>
                      <option>71</option>
                      <option>72</option>
                      <option>73</option>
                      <option>74</option>
                      <option>75</option>
                    </select>
                  </div>
                </div>
                <div v-show="isShowPad"></div>
              </div>

              <div class="w-3 flex row-star">
                <img
                  src="../../assets/20230905/nos.svg"
                  v-show="!isShowPad"
                  @click="showpad"
                />
                <img
                  src="../../assets/20230905/se.svg"
                  v-show="isShowPad"
                  @click="showpad"
                />
                <!-- <input type="checkbox" v-model="isShowPad" /> -->
                <div class="txt-16 pad-t-3">
                  {{ $t('message.prescription.have_2pd') }}
                </div>
              </div>

              <div class="ere_txt" v-show="right_axis > 180 || left_axis > 180">
                {{ $t('message.prescription.the_AXIS') }}
              </div>
            </div>

            <div class="paddd">
              <div class="txt-27">{{ $t('message.prescription.remark') }}</div>
              <div class="box01">
                <input
                  class="inputtxt"
                  :placeholder="this.$t('message.prescription.type_your')"
                  type="text"
                />
                <!-- <div class="txt-16 color-07">Type your message here</div> -->
              </div>

              <div class="m-t-32 flex row-between row-center">
                <div>
                  <img
                    src="../../assets/20230905/nos.svg"
                    class="block"
                    v-show="!isShow_1"
                    @click="show_1"
                  />
                  <img
                    src="../../assets/20230905/se.svg"
                    class="block"
                    v-show="isShow_1"
                    @click="show_1"
                  />
                  <!-- <input type="checkbox" class="block"/> -->
                </div>

                <div class="txt-16 w-4">
                  {{ $t('message.prescription.i_confirm') }}
                </div>
              </div>

              <div class="m-t-14 flex row-between row-center">
                <div>
                  <img
                    src="../../assets/20230905/nos.svg"
                    class="block"
                    v-show="!isShow_2"
                    @click="show_2"
                  />
                  <img
                    src="../../assets/20230905/se.svg"
                    class="block"
                    v-show="isShow_2"
                    @click="show_2"
                  />
                </div>

                <div class="txt-16 w-4">
                  {{ $t('message.prescription.i_acknowledge') }}
                </div>
              </div>
              <div class="m-t-14 flex row-between row-center">
                <div>
                  <img
                    src="../../assets/20230905/nos.svg"
                    class="block"
                    v-show="!isShow_3"
                    @click="show_3"
                  />
                  <img
                    src="../../assets/20230905/se.svg"
                    class="block"
                    v-show="isShow_3"
                    @click="show_3"
                  />
                </div>

                <div class="txt-16 w-4">
                  {{ $t('message.prescription.i_understand') }}
                </div>
              </div>
              <div class="m-t-14 flex row-between row-center">
                <div>
                  <img
                    src="../../assets/20230905/nos.svg"
                    class="block"
                    v-show="!isShow_4"
                    @click="show_4"
                  />
                  <img
                    src="../../assets/20230905/se.svg"
                    class="block"
                    v-show="isShow_4"
                    @click="show_4"
                  />
                </div>

                <div class="txt-16 w-4">
                  {{ $t('message.prescription.i_ifthere') }}
                </div>
              </div>

              <div class="box02 txt-21">
                {{ $t('message.prescription.coming_soon') }}
              </div>
            </div>

            <div class="paddd">
              <div class="flex row-between row-center cus" @click="showHeats">
                <img
                  src="../../assets/20230905/HeartStraight.svg"
                  class="himg"
                />
                <div class="txt-16 w-001">
                  {{ $t('message.lenses.care_instructions') }}
                </div>
                <img
                  src="../../assets/20230905/CaretDown.svg"
                  v-show="!showHeat"
                  class="dimg"
                />
                <img
                  src="../../assets/20230905/CaretUp.svg"
                  v-show="showHeat"
                  class="dimg"
                />
              </div>

              <div v-show="showHeat">
                <ol>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.gentle_cleaning') }}
                    </div>
                  </li>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.special_cloth') }}
                    </div>
                  </li>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.avoid_scratches') }}
                    </div>
                  </li>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.avoid_high') }}
                    </div>
                  </li>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.no_saliva') }}
                    </div>
                  </li>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.regular_inspection') }}
                    </div>
                  </li>
                  <li>
                    <div class="txt-16 m-t-12">
                      {{ $t('message.lenses.avoid_chemical') }}
                    </div>
                  </li>
                </ol>
              </div>
            </div>

            <div class="paddd m-t-16">
              <div class="flex row-start">
                <!-- <img src="../../assets/20230905/Share.svg" class="himg">
              <div class="txt-16 w-002">Share</div> -->
                <img
                  src="../../assets/20230905/LinkSimple.svg"
                  class="simg"
                  :class="mindex == 0 ? 'simg_active' : ''"
                  @click="copyClip"
                  @mouseenter="handleMouseEnter(0)"
                  @mouseleave="handleMouseLeave(0)"
                />
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblucapsport.com%2Fnews"
                  target="_blank"
                >
                  <img
                    src="../../assets/20230905/FacebookLogo.svg"
                    class="simg"
                    :class="mindex == 1 ? 'simg_active' : ''"
                    @mouseenter="handleMouseEnter(1)"
                    @mouseleave="handleMouseLeave(1)"
                /></a>
                <a
                  href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fblucapsport.com%2Fnews&amp;text=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride."
                  target="_blank"
                  ><img
                    src="../../assets/20230905/TwitterLogo.svg"
                    class="simg"
                    :class="mindex == 2 ? 'simg_active' : ''"
                    @mouseenter="handleMouseEnter(2)"
                    @mouseleave="handleMouseLeave(2)"
                /></a>
                <a
                  href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblucapsport.com%2Fnews"
                  target="_blank"
                >
                  <img
                    src="../../assets/20230905/LinkedinLogo.svg"
                    class="simg"
                    :class="mindex == 3 ? 'simg_active' : ''"
                    @mouseenter="handleMouseEnter(3)"
                    @mouseleave="handleMouseLeave(3)"
                /></a>
                <a
                  href="mailto:?subject=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride.&amp;body=https%3A%2F%2Fblucapsport.com%2Fnews"
                  target="_blank"
                  rel="nofollow"
                  ><img
                    src="../../assets/20230905/mail.svg"
                    :class="mindex == 4 ? 'simg_active' : ''"
                    class="simg"
                    @mouseenter="handleMouseEnter(4)"
                    @mouseleave="handleMouseLeave(4)"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <PCFooter />
    </div>

    <div class="tcc" v-show="tccShow">
      <div class="tcc-sshow">
        <div class="abso" @click="tcc">
          <img src="../../assets/20230905/X.svg" class="closeImage" />
        </div>
        <div class="tcc-title">
          {{ $t('message.prescription.understanding') }}
        </div>
        <div class="tcc-txt-21 tcc-m-t-24">
          {{ $t('message.prescription.right_eye') }}
        </div>
        <div class="tcc-txt-16">{{ $t('message.prescription.stands') }}</div>

        <div class="tcc-txt-21 tcc-m-t-24">
          {{ $t('message.prescription.left_eye') }}
        </div>
        <div class="tcc-txt-16">
          {{ $t('message.prescription.left_stands') }}
        </div>

        <div class="tcc-txt-21 tcc-m-t-24">
          {{ $t('message.prescription.sphere') }}
        </div>
        <div class="tcc-txt-16">
          {{ $t('message.prescription.they_mean') }}
        </div>

        <div class="tcc-txt-21 tcc-m-t-24">
          {{ $t('message.prescription.cyl') }}
        </div>
        <div class="tcc-txt-16">
          {{ $t('message.prescription.people') }}
        </div>

        <div class="tcc-txt-21 tcc-m-t-24">
          {{ $t('message.prescription.axis') }}
        </div>
        <div class="tcc-txt-16">
          {{ $t('message.prescription.this_is_only') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
import clipboard from 'clipboard'
import PCHeader1 from '@/components/header/PCHeader04.vue' //头部
import PCFooter from '@/components/footer/PCFoote01.vue' //底部
import PCPrescriptionSwipper from '@/components/prescription/PCPrescriptionSwipper.vue' //滚动
export default {
  components: {
    PCHeader1,
    PCFooter,
    PCPrescriptionSwipper,
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 1, //用来传值给子组件,来显示点击了第几个
      showHeat: false,
      isShowPad: false,
      index: 0,
      isShow_1: false,
      isShow_2: false,
      isShow_3: false,
      isShow_4: false,
      tccShow: false,
      rightCy: 0,
      leftCy: 0,
      right_axis: '',
      left_axis: '',
      right_sphere: -100,
      left_sphere: -100,
      rightPad: 0,
      leftPad: 0,
      pad: 0,
      mindex: 100,
    }
  },
  watch: {
    rightCy(newVal) {
      if (newVal == 0) {
        this.right_axis = ''
      }
    },
    leftCy(newVal) {
      if (newVal == 0) {
        this.left_axis = ''
      }
    },
  },
  created() {
    //   这个用来在不同分辨率等比缩放css样式
    // $(function () {
    //   var w = window.screen.width;
    //   var zoom = w / 1920;
    //   $("#app").css({
    //     zoom: zoom,
    //     "-moz-transform": "scale(" + zoom + ")",
    //     "-moz-transform-origin": "top left",
    //   });
    // });
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener('scroll', this.debounce(this.handleScroll, 10))
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (top < 60) {
        this.changeClass1 = 1
      } else {
        this.changeClass1 = 2
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null
      return function () {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    },

    showHeats() {
      this.showHeat = !this.showHeat
    },
    showpad() {
      this.isShowPad = !this.isShowPad
    },
    show_1() {
      this.isShow_1 = !this.isShow_1
    },
    show_2() {
      this.isShow_2 = !this.isShow_2
    },
    show_3() {
      this.isShow_3 = !this.isShow_3
    },
    show_4() {
      this.isShow_4 = !this.isShow_4
    },
    tcc() {
      this.tccShow = !this.tccShow
    },
    //复制
    copyClip() {
      let text = 'https://blucapsport.com/prescription'
      clipboard.copy(text)
      alert('已复制到剪贴板！')
    },
    handleMouseEnter(sindex) {
      this.mindex = sindex
    },
    handleMouseLeave(sindex) {
      console.log('sindex', sindex)
      this.mindex = 100
    },
  },
}
</script>
<style lang="less" scoped>
.alls {
  width: 100%;

  .foot {
    border-top: 1px solid #303030;
    margin-top: 60px;
  }
}
.cus {
  cursor: pointer;
}
.block {
  display: block;
}

ol {
  list-style-type: counter;
  margin-left: 45px;
  font-size: 16px;
}

.simg {
  cursor: pointer;
  display: block;
  opacity: 0.7;
  margin-right: 24px;
}

.simg_active {
  opacity: 1;
}

@media screen and(min-width: 1920px) {
  .content {
    min-height: 1080px;
    width: 1920px;
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 270px;
    padding-right: 270px;

    font-family: League Spartan;
    font-style: normal;
    color: #fff;
    .t-001 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    // element.style {
    //     position: relative;
    //     margin: 0 auto;
    //     height: 820px;
    //     overflow-y: auto;
    //     margin-top: -100px;
    //     padding-top: 100px;
    // }

    .w-2 {
      // width: 520px;
      flex-basis: 520px;
      max-width: 520px;
    }

    .txt-42 {
      font-size: 42px;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 27px;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21 {
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 16px;
      font-weight: 400;
      line-height: 110%;
    }

    .color-blue {
      color: #249eff;
      cursor: pointer;
    }

    .m-t-10 {
      margin-top: 10px;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
      padding-left: 0px;
      padding-right: 30px;
    }

    .m-t-14 {
      margin-top: 14px;
    }

    .m-t-24 {
      margin-top: 24px;
    }

    .m-t-32 {
      margin-top: 32px;
    }

    .paddd {
      padding-top: 16px;
      margin-top: 32px;
      border-top: 1px solid #fff;
    }

    .m-t-16 {
      margin-top: 16px;
    }

    .sl {
      width: 248px;
      font-size: 16px;
      padding: 0px 16px;
      background-color: #000;
      color: #fff;
      border: 1px solid #fff;
      display: block;
      margin-top: 10px;
      font-family: League Spartan;
      line-height: 44px;
    }

    .w-3 {
      // width: 165px;
      line-height: 20px;
      margin-top: 13px;
    }

    .box01 {
      width: 100%;
      border: 1px solid #fff;
      padding: 0px 16px;
      margin-top: 10px;
    }

    .box02 {
      width: 100%;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.24);
      text-align: center;
      padding: 16px;
      margin-top: 32px;
    }

    .w-001 {
      width: 460px;
    }

    .w-002 {
      width: 492px;
    }

    .dimg {
      cursor: pointer;
    }

    // .sl {
    //   /* 清除默认的箭头样式 */
    //   appearance: none;
    //   -moz-appearance: none;
    //   -webkit-appearance: none;
    //   /* 右侧添加小箭头的背景图 */
    //   background: url('../../assets/20230905/select.png') 230px center no-repeat;
    //   background-size: 12px;
    // }
  }

  .w-4 {
    width: 488px;
  }

  .w-1 {
    flex-basis: calc(100% - 520px);
    max-width: calc(100% - 520px);
    padding-right: 60px;
    top: 109px;
    height: calc(100vh - 30%);
    min-height: 690px;
    position: sticky;

    width: 820px;
    //position: relative;

    // .ptop {
    //   position: fixed;
    //   top: 100px;
    //   width: 820px;
    // }
  }

  li,
  img,
  label,
  input {
    vertical-align: top;
  }
}
</style>

<style lang="less" scoped>
@media screen and(max-width: 1919px) {
  .content {
    min-height: 1080px;
    width: 1440px;
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 30px;
    padding-right: 30px;

    font-family: League Spartan;
    font-style: normal;
    color: #fff;
    .t-001 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }
    .w-2 {
      width: 520px;
    }

    .txt-42 {
      font-size: 42px;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 27px;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21 {
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 16px;
      font-weight: 400;
      line-height: 110%;
    }

    .color-blue {
      color: #249eff;
    }

    .m-t-10 {
      margin-top: 10px;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
      padding-left: 0px;
      padding-right: 30px;
    }

    .m-t-14 {
      margin-top: 14px;
    }

    .m-t-24 {
      margin-top: 24px;
    }

    .m-t-32 {
      margin-top: 32px;
    }

    .paddd {
      padding-top: 16px;
      margin-top: 32px;
      border-top: 1px solid #fff;
    }

    .m-t-16 {
      margin-top: 16px;
    }

    .sl {
      width: 248px;
      font-size: 16px;
      padding: 0px 16px;
      background-color: #000;
      color: #fff;
      border: 1px solid #fff;
      display: block;
      margin-top: 10px;
      font-family: League Spartan;
      line-height: 44px;
    }

    .w-3 {
      // width: 165px;
      line-height: 20px;
      margin-top: 13px;
    }

    .box01 {
      width: 100%;
      border: 1px solid #fff;
      padding: 0px 16px;
      margin-top: 10px;
    }

    .box02 {
      width: 100%;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.24);
      text-align: center;
      padding: 16px;
      margin-top: 32px;
    }

    .w-001 {
      width: 460px;
    }

    .w-002 {
      width: 492px;
    }

    .dimg {
      cursor: pointer;
    }

    // .sl {
    //   /* 清除默认的箭头样式 */
    //   appearance: none;
    //   -moz-appearance: none;
    //   -webkit-appearance: none;
    //   /* 右侧添加小箭头的背景图 */
    //   background: url('../../assets/20230905/select.png') 230px center no-repeat;
    //   background-size: 12px;
    // }
    // ::-webkit-scrollbar {
    //         /* display: none; */
    //         /* overflow-x: hidden; */
    //     }
  }

  .w-4 {
    width: 488px;
  }

  .w-1 {
    flex-basis: calc(100% - 520px);
    max-width: calc(100% - 520px);
    padding-right: 60px;
    top: 109px;
    height: calc(100vh - 30%);
    min-height: 490px;
    position: sticky;

    //position: relative;
    width: 820px;

    .ptop {
      // position: fixed;
      // top: 100px;
      // width: 820px;
    }
  }

  li,
  img,
  label,
  input {
    vertical-align: top;
  }
}

.pad-t-3 {
  padding-top: 3px;
  margin-left: 12px;
}

.color-07 {
  color: rgba(255, 255, 255, 0.7);
}

select {
  /*去除外边框*/
  border: none;
  /*适应safari浏览器内容不跑偏*/
  // line-height: 1;
  width: 100%;
  /*将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*在选择框的最右侧中间显示小箭头图片*/
  background: url('../../assets/20230905/CaretDown.svg') no-repeat scroll right
    center transparent;
  background-size: 36px 20px;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-left: 16px;
  color: #666666;
  /*去除选中时样式*/
  outline: none;

  /*去除点击一闪而过的灰色背景*/

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>

<style lang="less" scoped>
.none {
  opacity: 0.5;
}

.content {
  .error {
    border: 2px solid #ff6060;
  }

  .ere_txt {
    color: #ff6060;
    font-family: League Spartan;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-top: 24px;
  }
}

.tcc {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(78, 78, 78, 0.42);
  z-index: 200;
  top: 0;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  .tcc-sshow {
    padding: 60px;
    width: 920px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
  }

  .abso {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }

  .tcc-title {
    font-family: League Spartan;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #fff;
  }

  .tcc-txt-21 {
    font-family: League Spartan;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #fff;
  }

  .tcc-txt-16 {
    font-family: League Spartan;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.7);
  }

  .tcc-m-t-24 {
    margin-top: 24px;
  }
}

.inputtxt {
  font-size: 16px;
  background-color: #000;
  display: block;
  color: rgba(255, 255, 255, 1);
  font-family: League Spartan;
  width: 100%;
  line-height: 44px;
}

.inputtxt::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.content {
  .scolor07 {
    color: rgba(255, 255, 255, 0.7);
  }

  .scolor10 {
    color: rgba(255, 255, 255, 1);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
