<template>
    <div class="all">
        <MPHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
        <div class="top">
            <PCPrescriptionSwipper :fromIndex="index" />
        </div>
        <div class="container">
            <div class="txt-42 m-t-30">{{ $t('message.prescription.prescription_lenses') }}</div>
            <div class="txt-21-01 m-t-8">{{ $t('message.prescription.money') }}</div>
            <div class="txt-16 m-t-12 color-07"> {{ $t('message.prescription.order_for') }}
            </div>
            <div class="txt-16 m-t-12 color-07"> {{ $t('message.prescription.experience') }}
            </div>
            <div class="txt-16 m-t-12 color-07"> {{ $t('message.prescription.smart_glasses') }}</div>

            <div class="padBox m-t-32">
                <div class="txt-27 m-t-24">{{ $t('message.prescription.prescription_information') }}</div>
                <div class="txt-016" @click="tcc">{{ $t('message.prescription.how_to_read') }}</div>

                <div class="m-t-24 flex row-between row-center">
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.right_sphere') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="right_sphere == -100 ? 'scolor07' : ''" v-model="right_sphere">
                                <option disabled selected value="-100">{{ $t('message.prescription.select') }}</option>
                                <option>-4.25</option>
                                <option>-4</option>
                                <option>-3.75</option>
                                <option>-3.5</option>
                                <option>-3.25</option>
                                <option>-3</option>
                                <option>-2.75</option>
                                <option>-2.5</option>
                                <option>-2.25</option>
                                <option>-2</option>
                                <option>-1.75</option>
                                <option>-1.5</option>
                                <option>-1.25</option>
                                <option>-1</option>
                                <option>-0.75</option>
                                <option>-0.5</option>
                                <option>-0.25</option>
                                <option>0</option>
                                <option>0.25</option>
                                <option>0.5</option>
                                <option>0.75</option>
                                <option>1</option>
                                <option>1.25</option>
                                <option>1.5</option>
                                <option>1.75</option>
                                <option>2</option>
                                <option>2.25</option>
                                <option>2.5</option>
                                <option>2.75</option>
                                <option>3</option>
                                <option>3.25</option>
                                <option>3.5</option>
                                <option>3.75</option>
                                <option>4</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.left_sphere') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="left_sphere == -100 ? 'scolor07' : ''" v-model="left_sphere">
                                <option disabled selected value="-100">{{ $t('message.prescription.select') }}</option>
                                <option>-4.25</option>
                                <option>-4</option>
                                <option>-3.75</option>
                                <option>-3.5</option>
                                <option>-3.25</option>
                                <option>-3</option>
                                <option>-2.75</option>
                                <option>-2.5</option>
                                <option>-2.25</option>
                                <option>-2</option>
                                <option>-1.75</option>
                                <option>-1.5</option>
                                <option>-1.25</option>
                                <option>-1</option>
                                <option>-0.75</option>
                                <option>-0.5</option>
                                <option>-0.25</option>
                                <option>0</option>
                                <option>0.25</option>
                                <option>0.5</option>
                                <option>0.75</option>
                                <option>1</option>
                                <option>1.25</option>
                                <option>1.5</option>
                                <option>1.75</option>
                                <option>2</option>
                                <option>2.25</option>
                                <option>2.5</option>
                                <option>2.75</option>
                                <option>3</option>
                                <option>3.25</option>
                                <option>3.5</option>
                                <option>3.75</option>
                                <option>4</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="m-t-24 flex row-between row-center">
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.right_cylinder') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="rightCy == 0 ? 'scolor07' : ''" v-model="rightCy">
                                <option value="0">{{ $t('message.prescription.none') }}</option>
                                <option>0.25</option>
                                <option>0.5</option>
                                <option>0.75</option>
                                <option>1</option>
                                <option>1.25</option>
                                <option>1.5</option>
                                <option>1.75</option>
                                <option>2</option>
                                <option>2.25</option>
                                <option>2.5</option>
                                <option>2.75</option>
                                <option>3</option>
                                <option>3.25</option>
                                <option>3.5</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.left_cylinder') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="leftCy == 0 ? 'scolor07' : ''" v-model="leftCy">
                                <option value="0">{{ $t('message.prescription.none') }}</option>
                                <option>0.25</option>
                                <option>0.5</option>
                                <option>0.75</option>
                                <option>1</option>
                                <option>1.25</option>
                                <option>1.5</option>
                                <option>1.75</option>
                                <option>2</option>
                                <option>2.25</option>
                                <option>2.5</option>
                                <option>2.75</option>
                                <option>3</option>
                                <option>3.25</option>
                                <option>3.5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="m-t-24 flex row-between row-center">
                    <div>
                        <div class="txt-16" :class="rightCy > 0 ? '' : 'none'">{{ $t('message.prescription.right_axis') }}</div>
                        <div class="m-t-8">
                            <input type="number" :placeholder="this.$t('message.prescription.none')" class="sl p-l-6"
                                oninput="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                                :class="[rightCy > 0 ? '' : 'none', right_axis > 180 ? 'error' : '']" v-model="right_axis"
                                :disabled="rightCy == 0" />
                        </div>
                    </div>
                    <div>
                        <div class="txt-16" :class="leftCy > 0 ? '' : 'none'">{{ $t('message.prescription.left_axis') }}</div>
                        <div class="m-t-8">
                            <input type="number" class="sl p-l-6" :placeholder="this.$t('message.prescription.none')"
                                oninput="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                                :class="[leftCy > 0 ? '' : 'none', left_axis > 180 ? 'error' : '']" v-model="left_axis"
                                :disabled="leftCy == 0" />
                        </div>
                    </div>
                </div>

                <div class="m-t-24 flex row-between row-center" v-show="isShowPad">
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.right_pd') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="rightPad == 0 ? 'scolor07' : ''" v-model="rightPad">
                                <option disabled selected value="0">{{ $t('message.prescription.select') }}</option>
                                <option>27.5</option>
                                <option>28</option>
                                <option>28.5</option>
                                <option>29</option>
                                <option>29.5</option>
                                <option>30</option>
                                <option>30.5</option>
                                <option>31</option>
                                <option>31.5</option>
                                <option>32</option>
                                <option>32.5</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.left_pd') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="leftPad == 0 ? 'scolor07' : ''" v-model="leftPad">
                                <option disabled selected value="0">{{ $t('message.prescription.select') }}</option>
                                <option>27.5</option>
                                <option>28</option>
                                <option>28.5</option>
                                <option>29</option>
                                <option>29.5</option>
                                <option>30</option>
                                <option>30.5</option>
                                <option>31</option>
                                <option>31.5</option>
                                <option>32</option>
                                <option>32.5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="m-t-24 flex row-between row-center" v-show="!isShowPad">
                    <div>
                        <div class="txt-16">{{ $t('message.prescription.pd') }}</div>
                        <div class="m-t-8">
                            <select class="sl" :class="pad == 0 ? 'scolor07' : ''" v-model="pad">
                                <option disabled selected value="0">{{ $t('message.prescription.select') }}</option>
                                <option>55</option>
                                <option>56</option>
                                <option>57</option>
                                <option>58</option>
                                <option>59</option>
                                <option>60</option>
                                <option>61</option>
                                <option>62</option>
                                <option>63</option>
                                <option>64</option>
                                <option>65</option>
                                <option>66</option>
                                <option>67</option>
                                <option>68</option>
                                <option>69</option>
                                <option>70</option>
                                <option>71</option>
                                <option>72</option>
                                <option>73</option>
                                <option>74</option>
                                <option>75</option>
                            </select>
                        </div>
                    </div>
                    <div v-show="isShowPad">

                    </div>
                </div>

                <div class="w-3 flex row-star">
                    <img src="../../assets/20230905/nos.svg" v-show="!isShowPad" @click="showpad" />
                    <img src="../../assets/20230905/se.svg" v-show="isShowPad" @click="showpad" />
                    <div class="txt-16 pad-t-3">{{ $t('message.prescription.have_2pd') }}</div>
                </div>

                <div class="ere_txt" v-show="(right_axis > 180 || left_axis > 180)">The AXIS values you’ve inserted are not
                    {{ $t('message.prescription.the_AXIS') }}
                </div>

            </div>

            <div class="padBox m-t-32">
                <div class="txt-16 m-t-24">{{ $t('message.prescription.remark') }}</div>
                <div class="box01">
                    <input class="inputtxt" :placeholder="this.$t('message.prescription.type_your')" type="text" />
                    <!-- <div class="txt-16 color-07">Type your message here</div> -->
                </div>

                <div class="m-t-32 flex row-between row-center">
                    <div>
                        <img src="../../assets/20230905/nos.svg" class="block" v-show="!isShow_1" @click="show_1" />
                        <img src="../../assets/20230905/se.svg" class="block" v-show="isShow_1" @click="show_1" />
                    </div>

                    <div class="txt-16 w-4">{{ $t('message.prescription.i_confirm') }}</div>
                </div>

                <div class="m-t-14 flex row-between row-center">
                    <div>
                        <img src="../../assets/20230905/nos.svg" class="block" v-show="!isShow_2" @click="show_2" />
                        <img src="../../assets/20230905/se.svg" class="block" v-show="isShow_2" @click="show_2" />
                    </div>

                    <div class="txt-16 w-4">
                        {{ $t('message.prescription.i_acknowledge') }}
                    </div>
                </div>

                <div class="m-t-14 flex row-between row-center">
                    <div>
                        <img src="../../assets/20230905/nos.svg" class="block" v-show="!isShow_3" @click="show_3" />
                        <img src="../../assets/20230905/se.svg" class="block" v-show="isShow_3" @click="show_3" />
                    </div>

                    <div class="txt-16 w-4">
                        {{ $t('message.prescription.i_understand') }}
                    </div>
                </div>

                <div class="m-t-14 flex row-between row-center">
                    <div>
                        <img src="../../assets/20230905/nos.svg" class="block" v-show="!isShow_4" @click="show_4" />
                        <img src="../../assets/20230905/se.svg" class="block" v-show="isShow_4" @click="show_4" />
                    </div>

                    <div class="txt-16 w-4">
                        {{ $t('message.prescription.i_ifthere') }}
                    </div>
                </div>

                <div class="box02 txt-21">{{ $t('message.prescription.coming_soon') }}</div>

            </div>

            <div class="padBox m-t-32" style="padding-top:0.16rem;">
                <div class="flex row-between row-center">
                    <img src="../../assets/20230905/HeartStraight.svg" class="himg">
                    <div class="txt-16 w-001">Care Instructions</div>
                    <img src="../../assets/20230905/CaretDown.svg" v-show="!showHeat" class="dimg" @click="showHeats">
                    <img src="../../assets/20230905/CaretUp.svg" v-show="showHeat" class="dimg" @click="showHeats">
                </div>
                <div v-show="showHeat">
                    <ol>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.gentle_cleaning') }}
                            </div>
                        </li>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.special_cloth') }}
                            </div>
                        </li>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.avoid_scratches') }}
                            </div>
                        </li>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.avoid_high') }}
                            </div>
                        </li>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.no_saliva') }}
                            </div>
                        </li>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.regular_inspection') }}
                            </div>
                        </li>
                        <li>
                            <div class="txt-16 m-t-12">
                                {{ $t('message.lenses.avoid_chemical') }}

                            </div>
                        </li>
                    </ol>
                </div>
            </div>

            <div class="padBox m-t-16">
                <div class="m-t-24 flex row-start">
                    <!-- <img src="../../assets/20230905/Share.svg" class="himg">
                    <div class="txt-16 w-002">Share</div> -->
                    <img src="../../assets/20230905/LinkSimple.svg" class="simg" :class="mindex == 0 ? 'simg_active' : ''"
                        @click="copyClip" @mouseenter="handleMouseEnter(0)" @mouseleave="handleMouseLeave(0)">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblucapsport.com%2Fnews"
                        target="_blank">
                        <img src="../../assets/20230905/FacebookLogo.svg" class="simg"
                            :class="mindex == 1 ? 'simg_active' : ''" @mouseenter="handleMouseEnter(1)"
                            @mouseleave="handleMouseLeave(1)"></a>
                    <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fblucapsport.com%2Fnews&amp;text=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride."
                        target="_blank"><img src="../../assets/20230905/TwitterLogo.svg" class="simg"
                            :class="mindex == 2 ? 'simg_active' : ''" @mouseenter="handleMouseEnter(2)"
                            @mouseleave="handleMouseLeave(2)"></a>
                    <a href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblucapsport.com%2Fnews"
                        target="_blank">
                        <img src="../../assets/20230905/LinkedinLogo.svg" class="simg"
                            :class="mindex == 3 ? 'simg_active' : ''" @mouseenter="handleMouseEnter(3)"
                            @mouseleave="handleMouseLeave(3)"></a>
                    <a href="mailto:?subject=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride.&amp;body=https%3A%2F%2Fblucapsport.com%2Fnews"
                        target="_blank" rel="nofollow"><img src="../../assets/20230905/mail.svg"
                            :class="mindex == 4 ? 'simg_active' : ''" class="simg" @mouseenter="handleMouseEnter(4)"
                            @mouseleave="handleMouseLeave(4)"></a>
                </div>
            </div>

        </div>
        <div class="bot">
            <MPFooter />
        </div>

        <div class="tcc" v-show="tccShow">
            <div class="tcc-sshow">
                <div class="abso" @click="tcc">
                    <img src="../../assets/20230905/X.svg" class="closeImage" />
                </div>
                <div class="tcc-title">{{ $t('message.prescription.understanding') }}</div>
                <div class="tcc-txt-21 tcc-m-t-24">{{ $t('message.prescription.right_eye') }}</div>
                <div class="tcc-txt-16">{{ $t('message.prescription.stands') }}</div>

                <div class="tcc-txt-21 tcc-m-t-24">{{ $t('message.prescription.left_eye') }}</div>
                <div class="tcc-txt-16">{{ $t('message.prescription.left_stands') }}</div>

                <div class="tcc-txt-21 tcc-m-t-24">{{ $t('message.prescription.sphere') }}</div>
                <div class="tcc-txt-16">
                    {{ $t('message.prescription.they_mean') }}
                </div>



                <div class="tcc-txt-21 tcc-m-t-24">{{ $t('message.prescription.cyl') }}</div>
                <div class="tcc-txt-16">{{ $t('message.prescription.people') }}</div>

                <div class="tcc-txt-21 tcc-m-t-24">{{ $t('message.prescription.axis') }}</div>
                <div class="tcc-txt-16">{{ $t('message.prescription.this_is_only') }}</div>

                <div class="tcc-border flex row-between row-center" @click="tcc">
                    <img src="../../assets/20230905/Check.svg" class="closimg" />
                    <div class="btntt">{{ $t('message.prescription.oK') }}</div>
                </div>
            </div>


        </div>


    </div>
</template>

<script>
import clipboard from 'clipboard';
import MPHeader1 from "@/components/header/MPHeader04.vue"; //头部
import MPFooter from "@/components/footer/MPFooter04.vue"; //底部
import PCPrescriptionSwipper from "@/components/prescription/PCPrescriptionSwipper.vue"; //滚动
export default {
    components: {
        MPHeader1,
        MPFooter,
        PCPrescriptionSwipper
    },
    data() {
        return {
            changeClass1: 1,
            tabIndexs1: 1, //用来传值给子组件,来显示点击了第几个
            newsList: this.$store.state.newsList,
            isShowPad: false,
            showHeat: false,
            index: 1,
            isShow_1: false,
            isShow_2: false,
            isShow_3: false,
            tccShow: false,
            isShow_4: false,
            rightCy: 0,
            leftCy: 0,
            right_axis: '',
            left_axis: '',
            right_sphere: -100,
            left_sphere: -100,
            rightPad: 0,
            leftPad: 0,
            pad: 0,
            mindex: 100,
        };
    },
    mounted() {
        //监听页面滚动事件
        // 要防抖
        window.addEventListener("scroll", this.debounce(this.handleScroll, 10));
    },
    watch: {
        rightCy(newVal) {
            if (newVal == 0) {
                this.right_axis = '';
            }
        },
        leftCy(newVal) {
            if (newVal == 0) {
                this.left_axis = '';
            }
        }
    },
    methods: {
        // 滑动
        handleScroll: function () {
            let top =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (top < 60) {
                this.changeClass1 = 1;
            } else {
                this.changeClass1 = 2;
            }
        },
        // 防抖函数
        debounce(fn, wait) {
            let timeout = null;
            return function () {
                if (timeout !== null) clearTimeout(timeout);
                timeout = setTimeout(fn, wait);
            };
        },
        showHeats() {
            this.showHeat = !this.showHeat;
        },
        showpad() {
            this.isShowPad = !this.isShowPad;
        },
        show_1() {
            this.isShow_1 = !this.isShow_1;
        },
        show_2() {
            this.isShow_2 = !this.isShow_2;
        },
        show_3() {
            this.isShow_3 = !this.isShow_3;
        },
        show_4() {
            this.isShow_4 = !this.isShow_4;
        },
        tcc() {
            this.tccShow = !this.tccShow;
        },
        //复制
        copyClip() {
            let text = 'https://blucapsport.com/prescription';
            clipboard.copy(text);
            alert('已复制到剪贴板！');
        },
        handleMouseEnter(sindex) {
            this.mindex = sindex;
        },
        handleMouseLeave(sindex) {
            console.log('sindex', sindex);
            this.mindex = 100;
        }
    }
};
</script>

<style lang="less" scoped>
.simg {
    cursor: pointer;
    display: block;
    opacity: 0.7;
    margin-right: 0.24rem;
}

.simg_active {
    opacity: 1;
}

ol {
    list-style-type: counter;
    margin-left: 0.45rem;
    font-size: 0.16rem;
}

.all {
    width: 100%;

    .top {
        width: 100%;
    }

    .container {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-family: League Spartan;
        font-style: normal;
        color: #fff;

        .txt-42 {
            font-size: 0.42rem;
            font-weight: 500;
            line-height: normal;
        }

        .txt-27 {
            font-size: 0.27rem;
            font-weight: 500;
            line-height: 120%;
        }

        .txt-21-01 {
            font-family: Oswald;
            font-size: 0.21rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }

        .txt-16 {
            font-size: 0.16rem;
            font-weight: 400;
            line-height: 120%;
        }

        .txt-016 {
            color: var(--web-blue, #249EFF);
            font-family: League Spartan;
            font-size: 0.16rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-decoration-line: underline;
        }



        .m-t-32 {
            margin-top: 0.32rem;
        }

        .m-t-30 {
            margin-top: 0.3rem;
        }

        .m-t-24 {
            margin-top: 0.24rem;
        }

        .m-t-16 {
            margin-top: 0.16rem;
        }

        .m-t-14 {
            margin-top: 0.14rem;
        }

        .m-t-12 {
            margin-top: 0.12rem;
        }

        .m-t-8 {
            margin-top: 0.08rem;
        }

        .padBox {
            border-top: 0.01rem solid #fff;

            .sl {
                width: 1.5rem;
                font-size: 0.16rem;
                background-color: #000;
                color: #fff;
                border: 0.01rem solid #fff;
                height: 0.44rem;
            }

            .scolor07 {
                color: rgba(255, 255, 255, 0.7);
            }

            .scolor10 {
                color: rgba(255, 255, 255, 1);
            }

            .error {
                border: 0.02rem solid #FF6060;
            }


            .w-3 {
                // width: 1.6rem;
                margin-top: 0.12rem;
            }

            .box01 {
                width: 100%;
                border: 0.01rem solid #fff;
                padding: 0.14rem 0.16rem;
                margin-top: 0.1rem;
            }

            .w-4 {
                width: 2.9rem;
                // margin-left: 0.12rem;
                margin-top: 0.04rem;
            }

            .box02 {
                width: 100%;
                background-color: #fff;
                color: rgba(0, 0, 0, 0.24);
                //color: #000;
                text-align: center;
                padding: 0.16rem;
                margin-top: 0.32rem;
            }

            .w-001 {
                width: 2.6rem;
            }

            .w-002 {
                width: 2.9rem;
            }
        }
    }

    .bot {
        border-top: 0.01rem solid #303030;
        margin-top: 0.34rem;
    }
}

.pad-t-3 {
    padding-top: 0.03rem;
    margin-left: 0.12rem;
}

.color-07 {
    color: rgba(255, 255, 255, 0.70);
}

select {

    /*去除外边框*/
    border: none;
    /*适应safari浏览器内容不跑偏*/
    line-height: 1;
    width: 100%;
    /*将默认的select选择框样式清除*/
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    /*在选择框的最右侧中间显示小箭头图片*/
    background: url("../../assets/20230905/CaretDown.svg") no-repeat scroll right center transparent;
    background-size: 0.36rem 0.2rem;
    /*为下拉小箭头留出一点位置，避免被文字覆盖*/
    padding-left: 0.16rem;
    font-size: 1.4rem;
    color: #666666;
    /*去除选中时样式*/
    outline: none;

    /*去除点击一闪而过的灰色背景*/

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}
</style>


<style lang="less" scoped>
.none {
    opacity: 0.5;
}


.ere_txt {
    color: #FF6060;
    font-family: League Spartan;
    font-size: 0.16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-top: 0.24rem;
}

.p-l-6 {
    padding-left: 0.16rem;
    padding-right: 0.16rem;
}

.tcc {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1000;
    top: 0;

    text-align: left;

    .tcc-sshow {
        padding: 0.6rem 0.3rem;
        height: 100%;
        overflow-y: scroll;
    }

    .abso {
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
    }

    .tcc-title {
        font-family: League Spartan;
        font-size: 0.27rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        color: #fff;
    }

    .tcc-txt-21 {
        font-family: League Spartan;
        font-size: 0.21rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #fff;
    }

    .tcc-txt-16 {
        font-family: League Spartan;
        font-size: 0.16rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.7);
    }

    .tcc-m-t-24 {
        margin-top: 0.24rem;
    }

    .tcc-border {
        width: 1.74rem;
        height: 0.5rem;
        border: 1.5px solid #fff;
        background: rgba(0, 0, 0, 0.01);
        backdrop-filter: blur(0.15rem);
        margin-left: 0.7rem;
        margin-top: 0.24rem;

        .closimg {
            margin-left: 0.59rem;
            width: 0.19rem;
            margin-top: -0.05rem;
        }

        .btntt {
            font-family: League Spartan;
            font-size: 0.21rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.5rem;
            margin-right: 0.59rem;
            color: #fff;
        }
    }
}

.inputtxt {
    font-size: 0.16rem;
    background-color: #000;
    display: block;
    color: rgba(255, 255, 255, 1);
    font-family: League Spartan;
    width: 100%;
}

.inputtxt::placeholder {
    color: rgba(255, 255, 255, 0.7);
}
</style>