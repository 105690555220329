<template>
  <div class="all">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <img :src="item" alt class="img" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev" v-show="fromIndex == 0"></div>
      <div class="swiper-button-next" v-show="fromIndex == 0"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
export default {
  props: {
    fromIndex: Number,
  },
  data() {
    return {
      // 轮播图
      swiperList: [
        require('../../assets/20240319/Prescription0.png'),
        require('../../assets/20240319/Prescription1.png'),
        require('../../assets/20240319/Prescription2.png'),
        require('../../assets/20240319/Prescription3.png'),
        require('../../assets/20240319/Prescription4.png'),
        require('../../assets/20240319/Prescription5.png'),
        require('../../assets/20240319/Prescription6.png'),
      ],
      //isShow:fromIndex==0?true:false
    }
  },
  activated() {
    this.$nextTick(() => {
      this.swiper()
    })
  },

  methods: {
    // 轮播图
    swiper() {
      new Swiper('.swiper-container', {
        pagination: { el: '.swiper-pagination' },
        //loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {
        //     disableOnInteraction: false,
        //     delay: 2000,
        // },
      })
    },
  },
}
</script>

<style lang="less">
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url(../../assets/20230905/ArrowCircleLeft.svg) !important;
  width: 42px !important;
  height: 42px !important;
  background-size: 100% 100% !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url(../../assets/20230905/ArrowCircleRight.svg) !important;
  width: 42px !important;
  height: 42px !important;
  background-size: 100% 100% !important;
}
</style>

<style lang="less" scoped>
.all {
  width: 100%;

  .img {
    width: 100%;
  }
}
</style>
